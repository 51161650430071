import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { Filter2Pipe } from './filter2.pipe';

@NgModule({
  declarations: [InitialsPipe, FilterPipe, Filter2Pipe,StripHtmlPipe, SafePipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, Filter2Pipe,StripHtmlPipe, SafePipe]
})
export class CorePipesModule {}
