import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";

import { CoreConfigService } from "@core/services/config.service";
import { CoreMenuService } from "@core/components/core-menu/core-menu.service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CustomerService } from "services/customer.service";
import { Router } from "@angular/router";
import { DataService } from "services/data.service";

@Component({
  selector: "horizontal-menu",
  templateUrl: "./horizontal-menu.component.html",
  styleUrls: ["./horizontal-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HorizontalMenuComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  currentUser: any;
  isAdmin: any;
  isManager: any;

  sepetAdet: any = 0;
  sepet: any;
  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _coreMenuService: CoreMenuService,
    private router: Router,
    private dataService: DataService,
    private _coreSidebarService: CoreSidebarService,
    private customerService: CustomerService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.dataService.currentbasketDataSource.subscribe((x) => {
      this.sepet = JSON.parse(localStorage.getItem("currentCart"));
      console.log("SEPETTTTTTT", this.sepet);
      if (this.sepet) {
        this.sepetAdet = this.sepet.length;
      } else {
        this.sepetAdet = 0;
      }
    });
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  seletedId: any;
  /**
   * On Init
   */
  ngOnInit(): void {
    this.dataService.currentnavSelect.subscribe((x) => {
      console.log("mutsy", x);
      this.seletedId = x;
    });

    this.seletedId = 1;
    this.dataService.changeNavSelect(1);
    // Subscribe config change
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    // Get current menu
    this._coreMenuService.onMenuChanged
      .pipe(
        filter((value) => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.menu = this._coreMenuService.getCurrentMenu();
        console.log("MENUUU", this.menu);
      });

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log("MENU KSIMI", this.currentUser);
    if (this.currentUser.username == "remzikaplan") {
      this.isManager = true;
    } else if (this.currentUser.username == "hakantp") {
      this.isManager = true;
    } else {
      this.isManager = false;
    }

    console.log("sss", this.currentUser.username);
    this.customerService.getRole(this.currentUser.id).subscribe((x: any) => {
      console.log("User role", x);
      this.isAdmin = x.responseBody[0].isAdmin;

      if (this.isAdmin) {
        this.seletedId = 4;
      }
    });
  }

  routerAnaSayfaPage() {
    this.seletedId = 1;
    this.dataService.changeNavSelect(1);
    this.router.navigate(["/dashboard/ecommerce"]);
  }
  routerUrunPage() {
    this.seletedId = 2;
    this.dataService.changeNavSelect(2);
    this.router.navigate(["/apps/e-commerce/products"]);
  }
  routerSepetPage() {
    this.seletedId = 3;
    this.dataService.changeNavSelect(3);
    this.router.navigate(["/apps/e-commerce/checkout"]);
  }
  routerSiparisTakipPage() {
    this.seletedId = 4;
    this.dataService.changeNavSelect(4);
    this.router.navigate(["/ui/orderTracking"]);
  }
  routerDoc() {
    this.seletedId = 5;
    this.dataService.changeNavSelect(5);
    this.router.navigate(["/ui/catalog"]);
  }
  routerAddProductPage() {
    this.seletedId = 6;
    this.dataService.changeNavSelect(6);
    this.router.navigate(["/apps/e-commerce/addproduct"]);
  }
  routerWhistlist() {
    this.seletedId = 7;
    this.dataService.changeNavSelect(7);
    this.router.navigate(["/apps/e-commerce/wishlist"]);
  }

  routerRoleDefinition() {
    this.seletedId = 8;
    this.dataService.changeNavSelect(8);
    this.router.navigate(["/apps/e-commerce/roledefinition"]);
  }
  routerMOil() {
    this.seletedId = 12;
    this.dataService.changeNavSelect(12);
    this.router.navigate(["/apps/e-commerce/moil"]);
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
