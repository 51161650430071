<!-- vertical-layout -->
<!-- kullanılmadı için kapatıldı -->
<!-- <ng-container *ngIf="coreConfig.layout.type === 'vertical'">
  <vertical-layout></vertical-layout>
</ng-container> -->
<!-- / vertical-layout -->

<!-- horizontal-layout -->

<!-- TODO verical horizontal hatası çıkarsa if kaldıralacak -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
  <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->

<!-- theme customizer -->
<!-- burak - seting site içiçn kapatıldı -->


<!-- TODO burası kapatılacak-->


<!-- <core-sidebar name="themeCustomizer" class="customizer d-none d-md-block" [invisibleOverlay]="true"
  *ngIf="coreConfig.layout.customizer">
  <a class="customizer-toggle d-flex align-items-center justify-content-center"
    (click)="toggleSidebar('themeCustomizer')">
    <span [data-feather]="'settings'" [class]="'spinner'"></span>
  </a>
  <core-theme-customizer></core-theme-customizer>
</core-sidebar> -->


<!-- / theme customizer -->