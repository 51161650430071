<div class="navbar-container main-menu-content">
  <ul id="main-menu-navigation" layout="horizontal" class="nav navbar-nav">
    <li class="dropdown nav-item ng-star-inserted" *ngIf="!isAdmin">
      <a
        class="d-flex deneme align-items-center nav-link"
        [ngStyle]="{
          'background-color': seletedId === 1 ? 'red' : '',
          color: seletedId === 1 ? 'white' : ''
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-home"
        >
          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
          <polyline points="9 22 9 12 15 12 15 22"></polyline>
        </svg>
        <a
          class="ng-star-inserted"
          [ngStyle]="{ 'font-weight': seletedId === 1 ? '600' : '400' }"
          (click)="routerAnaSayfaPage()"
          >Ana Sayfa</a
        >
      </a>
    </li>
    <li
      class="dropdown nav-item ng-star-inserted"
      *ngIf="!isAdmin"
      routerLinkActive="active-link"
    >
      <a
        class="d-flex deneme12 align-items-center nav-link"
        (click)="routerUrunPage()"
        [ngStyle]="{
          'background-color': seletedId === 2 ? 'red' : '',
          color: seletedId === 2 ? 'white' : ''
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-grid"
        >
          <rect x="3" y="3" width="7" height="7"></rect>
          <rect x="14" y="3" width="7" height="7"></rect>
          <rect x="14" y="14" width="7" height="7"></rect>
          <rect x="3" y="14" width="7" height="7"></rect>
        </svg>
        <a
          class="ng-star-inserted"
          [ngStyle]="{ 'font-weight': seletedId === 2 ? '600' : '400' }"
          >Ürünler</a
        >
      </a>
    </li>
    <li class="dropdown nav-item ng-star-inserted" *ngIf="!isAdmin">
      <a
        class="d-flex align-items-center nav-link"
        (click)="routerSepetPage()"
        [ngStyle]="{
          'background-color': seletedId === 3 ? 'red' : '',
          color: seletedId === 3 ? 'white' : ''
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-shopping-cart"
        >
          <circle cx="9" cy="21" r="1"></circle>
          <circle cx="20" cy="21" r="1"></circle>
          <path
            d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
          ></path>
        </svg>
        <a
          class="ng-star-inserted"
          [ngStyle]="{ 'font-weight': seletedId === 3 ? '600' : '400' }"
          >Sepet</a
        >
        <span
          class="float-right badge badge-danger badge-pill ng-star-inserted"
          style="position: absolute; top: 0; right: -4px"
          >{{ sepetAdet }}</span
        >
      </a>
    </li>
    <li class="dropdown nav-item ng-star-inserted">
      <a
        class="d-flex align-items-center nav-link"
        (click)="routerSiparisTakipPage()"
        [ngStyle]="{
          'background-color': seletedId === 4 ? 'red' : '',
          color: seletedId === 4 ? 'white' : ''
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-clipboard"
        >
          <path
            d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"
          ></path>
          <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
        </svg>
        <a
          class="ng-star-inserted"
          [ngStyle]="{ 'font-weight': seletedId === 4 ? '600' : '400' }"
          >Sipariş Takibi</a
        >
      </a>
    </li>
    <li class="dropdown nav-item ng-star-inserted">
      <a
        class="d-flex align-items-center nav-link"
        (click)="routerDoc()"
        [ngStyle]="{
          'background-color': seletedId === 5 ? 'red' : '',
          color: seletedId === 5 ? 'white' : ''
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-book-open"
        >
          <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
          <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
        </svg>
        <a
          class="ng-star-inserted"
          [ngStyle]="{ 'font-weight': seletedId === 5 ? '600' : '400' }"
          >TP Katalog</a
        >
      </a>
    </li>
    <li class="dropdown nav-item ng-star-inserted" *ngIf="isAdmin">
      <a
        class="d-flex align-items-center nav-link"
        (click)="routerAddProductPage()"
        [ngStyle]="{
          'background-color': seletedId === 6 ? 'red' : '',
          color: seletedId === 6 ? 'white' : ''
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-plus-square"
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <line x1="12" y1="8" x2="12" y2="16" />
          <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
        <a
          class="ng-star-inserted"
          [ngStyle]="{ 'font-weight': seletedId === 6 ? '600' : '400' }"
          >Ürün Ekle</a
        >
      </a>
    </li>

    <li class="dropdown nav-item ng-star-inserted" *ngIf="!isAdmin">
      <a
        class="d-flex align-items-center nav-link"
        (click)="routerWhistlist()"
        [ngStyle]="{
          'background-color': seletedId === 7 ? 'red' : '',
          color: seletedId === 7 ? 'white' : ''
        }"
      >
        <i class="fa mr-25 fa-heart-o"></i>
        <a
          class="ng-star-inserted"
          [ngStyle]="{ 'font-weight': seletedId === 7 ? '600' : '400' }"
          >Favoriler</a
        >
      </a>
    </li>

    <li class="dropdown nav-item ng-star-inserted" *ngIf="isAdmin">
      <a
        class="d-flex align-items-center nav-link"
        (click)="routerRoleDefinition()"
        [ngStyle]="{
          'background-color': seletedId === 8 ? 'red' : '',
          color: seletedId === 8 ? 'white' : ''
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-plus-square"
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <line x1="12" y1="8" x2="12" y2="16" />
          <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
        <a
          class="ng-star-inserted"
          [ngStyle]="{ 'font-weight': seletedId === 8 ? '600' : '400' }"
          >Rol Tanımlama</a
        >
      </a>
    </li>


    
    <li class="dropdown nav-item ng-star-inserted" *ngIf="isManager">
      <a
        class="d-flex align-items-center nav-link"
        (click)="routerMOil()"
        [ngStyle]="{
          'background-color': seletedId === 12 ? 'red' : '',
          color: seletedId === 12 ? 'white' : ''
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-upload"
        >
          <line x1="12" y1="1" x2="12" y2="23" />
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="17 8 12 3 7 8"></polyline>
        </svg>
        <a
          class="ng-star-inserted"
          [ngStyle]="{ 'font-weight': seletedId === 12 ? '600' : '400' }"
          >Excel Upload</a
        >
      </a>
    </li>
  </ul>
</div>
