import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  baseUrl = environment.baseUrl + "/customers";
  constructor(private http: HttpClient) { }

  getCustomers(customerId: number) {
    return this.http.get(this.baseUrl + "/dtl/" + customerId);
  }

  addCustomer(customer: any) {
    //   {
    //     "id": 7,
    //     "cusCode": "TPADM       ",
    //     "cusName": "TÜRKİYE PETROLLERİ",
    //     "cusNumber": "7",
    //     "isActive": false,
    //     "taxNumber": "8590975887",
    //     "taxAdministration": "ÜMRANİYE VERGİ DAİRESİ MÜDÜRLÜĞÜ",
    //     "custLevel": 0,
    //     "sectorId": 152,
    //     "sectorType": 0,
    //     "latitude": 0.0,
    //     "longitude": 0.0,
    //     "iconImg": "0",
    //     "custImg": "0",
    //     "zoomLevel": 0,
    // }
    return this.http.post(this.baseUrl + "address/add", customer);
  }

  deleteCustomer(id: number) {
    return this.http.delete(this.baseUrl + "/delete/" + id);
  }

  updateCustomer(customer: any) {
    return this.http.put(this.baseUrl + "/update/" + customer.id, customer);
  }

  getCustomerAddressByCustomerId(customerId: number) {
    return this.http.get(this.baseUrl + "/address/" + customerId);
  }

  updateCustomerAddress(customerAddress: any) {
    return this.http.put(
      this.baseUrl + "/address/update/" + customerAddress.id,
      customerAddress
    );
  }

  getListOfValuesByName(name: string) {
    return this.http.get(
      environment.baseUrl + "/attributes/listofvalues/" + name
    );
  }
  //Kullanıcı id sine göre rollerinin listelenmesi
  getRole(userId: number) {
    return this.http.get(environment.baseUrl + "/users/" + userId + "/roles");
  }
  getRolesX(userId: any) {
    return this.http.get(environment.baseUrl + "/users/" + userId + "/rolesx");
  }
  // Bütün rollerin listlenmesi
  getAllRole() {
    return this.http.get(environment.baseUrl + "/roles/");
  }
  //Kullanıcıların listelenmesi
  getAll() {
    return this.http.get(environment.baseUrl + "/users/");
  }
  //Yeni rol ekleme
  addRole(role: any) {
    console.log("role model in customerService", role)

    return this.http.post(environment.baseUrl + "/roles/" + "add", role);
  }

  //Kullanıcıya yeni rol ekleme
  addUserRole(role: any) {
    console.log(role)
    return this.http.post(environment.baseUrl + '/users/role/add',
      {
        "userId": role.userId,
        "roleId": role.roleId
      });
  }
  //Kullanıcının rolünü silme
  deleteUserRole(id: any) {
    console.log(id)
    return this.http.delete(environment.baseUrl + '/users/role/delete/' + id);
  }
  // Genel rol güncelleme
  updateRole(role: any) {
    return this.http.put(environment.baseUrl + "/roles/update/" + role.id, role);
  }
  // Genel rol silme
  deleteRole(roleId: any) {
    return this.http.delete(environment.baseUrl + "/roles/delete/" + roleId);
  }


  postMOil(formData: any) {
    return this.http.post(environment.baseUrl + "/excel/import", formData);
  }
  postExcel(formData: any) {
    return this.http.post(environment.baseUrlTigerPetrol + "/excel/importyagbulucu", formData);
  }
  postExcelSiparis(formData: any) {
    return this.http.post(environment.baseUrlTigerPetrol + "/excel/importsiparisler", formData);
  }

  //http://localhost:5252/api/category

  getCategory() {
    return this.http.get(environment.baseUrl + "/category");
  }

  getPackageValues() {
    return this.http.get(environment.baseUrl + "/packageValues");
  }
}
