<!-- Bookmarked Icons -->
<ul class="nav navbar-nav bookmark-icons">
  <ng-container>
    <li class="nav-item d-none d-lg-block">
      <a class="nav-link">
        <span >Ana Sayfass</span>
      </a>
    </li>
    <li class="nav-item d-none d-lg-block">
      <a class="nav-link">
        <span>Ürünler</span>
      </a>
    </li>
    <li class="nav-item d-none d-lg-block">
      <a class="nav-link">
        <span>Sipariş Takibi</span>
      </a>
    </li>
    <li class="nav-item d-none d-lg-block">
      <a class="nav-link">
        <span>Profil</span>
      </a>
    </li>
  </ng-container>
</ul>
<!-- Bookmarked Icons -->


