import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DataService {


    private customerDataSource = new BehaviorSubject<any>({});
    currentCustomerDataSource = this.customerDataSource.asObservable();

    changeCustomerData(data: any) {
        this.customerDataSource.next(data);
    }

    private navSelect = new BehaviorSubject<number>(0);
    currentnavSelect = this.navSelect.asObservable();

    changeNavSelect(data: any) {
        this.navSelect.next(data);
    }



    private basketDataSource = new BehaviorSubject<any>([]);
    currentbasketDataSource = this.basketDataSource.asObservable();

    changeBasketData(data: any) {
        localStorage.setItem('currentCart', JSON.stringify(data));
        this.basketDataSource.next(data);
    }

    addToCart(data: any) {
        let alldata = JSON.parse(localStorage.getItem('currentCart'));
        if (alldata) {
            alldata.push(data);
        }
        else {
            alldata = []
            alldata.push(data);
        }
        this.changeBasketData(alldata);
    }

    isOnCart(productId: number) {
        let data = JSON.parse(localStorage.getItem('currentCart'));
        if (data) {
            return (data.filter(a => a.product.id === productId).length > 0) ? true : false;
        }
        return false;
    }

    removoToCart(productId: any) {
        console.log(productId + " id li kayıt silinmiş session verisi", JSON.parse(localStorage.getItem('currentCart')).filter(a => a.product.id !== productId))
        this.changeBasketData(JSON.parse(localStorage.getItem('currentCart')).filter(a => a.product.id !== productId))
    }

    clearToCart(){
        this.changeBasketData([])
    }


    private invoiceDataSource = new BehaviorSubject<any>({});
    currentInvoiceDataSource = this.invoiceDataSource.asObservable();

    changeInvoiceData(data: any) {
        this.invoiceDataSource.next(data);
    }


    private seletedProductDataSource = new BehaviorSubject<any>({});
    currentSeletedProductDataSource = this.seletedProductDataSource.asObservable();

    changeSeletedProductData(data: any) {
        this.seletedProductDataSource.next(data);
    }


}
