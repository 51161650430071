import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  baseUrl = environment.baseUrl + "/product";
  constructor(private http: HttpClient) {}

  getAllProduct() {
    return this.http.get(this.baseUrl);
  }

  getProductPrice2() {
    return this.http.get(this.baseUrl + "/limit");
  }

  getProductById(productId: number) {
    return this.http.get(this.baseUrl + "/" + productId);
  }

  addProduct(product: any) {
    console.log("product model", product);

    /* 
        ----product model example
        {
            "productName": "product",
            "productCode": "code",
            "marka": {
                "id": 1
            },
            "miktar": {
                "id": 1
            },
            "productStatus": {
                "id": 1
            },
            "productBarkod": "1",
            "documentUrl": "1",
            "imageUrl": "1",
            "category": {
                "id": 1
            }
        }

        */
    return this.http.post(this.baseUrl + "/add", product);
  }

  deleteProduct(productId: number) {
    return this.http.delete(this.baseUrl + "/delete/" + productId);
  }

  updateAttribute(product: any) {
    console.log("product model", product);

    /* 
        ----product model example
        {
            "id" : 1
            "productName": "product",
            "productCode": "code",
            "marka": {
                "id": 1
            },
            "miktar": {
                "id": 1
            },
            "productStatus": {
                "id": 1
            },
            "productBarkod": "1",
            "documentUrl": "1",
            "imageUrl": "1",
            "category": {
                "id": 1
            }
        }
        */
    return this.http.put(this.baseUrl + "/update/" + product.id, product);
  }

  getProductPriceByProductId(productId: number) {
    return this.http.get(this.baseUrl + "/" + productId + "/price");
  }

  getProductPrice() {
    return this.http.get(this.baseUrl + "/price");
  }

  addProductPrice(product: any) {
    console.log("productprice model", product);

    // {
    //     "product": {
    //         "id": 55
    //     },
    //     "birimFiyat": 301,
    //     "piyasaSatisFiyati": 301,
    //     "kdv": 18,
    //     "otv": 1,
    //     "createdDateTime": "2021-06-22T13:13:02.000+00:00",
    //     "updatedDateTime": null
    // }

    return this.http.post(this.baseUrl + "/price/add", product);
  }

  addProductStock(product: any) {
    console.log("productstock model", product);

    // {
    //     "product": {
    //         "id": data.responseBody[0].id,
    //     },
    //     "adet": this.inputForm.value.urunstok,
    //     "tpStockCode": 1111
    // };

    return this.http.post(this.baseUrl + "/stock/add", product);
  }
}
