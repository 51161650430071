<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" >
        <span class="brand-logo">
          <img src="assets/images/logo/tplogoduzenlenms.png" alt="brand-logo" width="36"  (click)="routerPage()"/>
        </span>

      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <!-- <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li> -->
    </ul>
    <!--/ Menu Toggler -->

    <!-- burak - navbar menu kapatılması -->
    <!-- Bookmark -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center">
    <!-- Language selection -->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->


    <!-- Search -->
    <app-navbar-search *ngIf="!isAdmin"></app-navbar-search>
    <!--/ Search -->

    <!-- Cart -->
    <!-- <app-navbar-cart></app-navbar-cart> -->
    <!--/ Cart -->



  </ul>
  <ul class="nav navbar-nav align-items-center ml-auto">



    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    <!--/ Toggle skin -->




    <!-- Notification -->
    <!-- <app-navbar-notification></app-navbar-notification> -->
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">{{this.currentUser.username}}</span><span
              class="user-status">{{ this.currentUser.role }}</span>
          </div>
          <span class="avatar"><img class="round" src="assets/images/portrait/small/tplogo.png" alt="avatar" height="30"
              width="30" /><span class="avatar-status-online"></span></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <!-- <a ngbDropdownItem [routerLink]="['/pages/profile']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profil</a> -->
        <!-- <div class="dropdown-divider"></div> -->
        <!-- <a ngbDropdownItem [routerLink]="['/pages/account-settings']">
          <span [data-feather]="'settings'" [class]="'mr-50'"></span> Ayarlar</a> -->

        <!-- AÇILACAKkk -->
        <a ngbDropdownItem (click)="goProfile()">
          <span [data-feather]="'user'" [class]="'mr-50'"></span> Profil Ayarları
        </a>
        <a ngbDropdownItem (click)="logout()">
          <span [data-feather]="'power'" [class]="'mr-50'"></span> Çıkış
        </a>
      </div>
    </li>

    <!-- pages/profile -->
    <!--/ User Dropdown -->
  </ul>
</div>