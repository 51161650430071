import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'services/customer.service';
import { DataService } from 'services/data.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService, private customerService: CustomerService, private dataService: DataService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.baseUrl}/auth/signin`, { username: email, password: password })
      .pipe(
        map(user => {
          console.log(user)
          // login successful if there's a jwt token in the response
          if (user && user.responseBody[0]) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.responseBody[0]));


            this.customerService.getCustomers(user.responseBody[0].customerId).subscribe((x: any) => {
              console.log("CUSTOMER GET", x)
              localStorage.setItem('currentCustomer', JSON.stringify(x.responseBody[0]));
              this.dataService.changeCustomerData(x.responseBody[0])
            })


            // Display welcome toast!
            // setTimeout(() => {
            //   this._toastrService.success(
            //     'You have successfully logged in as an ' +
            //     user.role +
            //     ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
            //     '👋 Welcome, ' + user.firstName + '!',
            //     { toastClass: 'toast ngx-toastr', closeButton: true }
            //   );
            // }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.dataService.changeCustomerData(null)
    localStorage.removeItem('currentCustomer');
    localStorage.removeItem('currentCart');
    // notify
    this.currentUserSubject.next(null);
  }
}
